<template>
  <div class="tuev">
    <NuxtLink
      to="https://www.pflege.de/tuev-zertifikat"
      target="_blank"
      title="Zum Testergebnis vom TÜV-Saarland"
      external
    >
      <img
        alt="TÜV bewertet pflege.de mit: Sehr Gut"
        src="https://static-assets.pflege.de/tuev/tuev_big.png"
        srcset="
          https://static-assets.pflege.de/tuev/tuev_big.png    1x,
          https://static-assets.pflege.de/tuev/tuev_big@2x.png 2x
        "
      />
    </NuxtLink>
    <p v-if="props.copy" class="copy">
      TÜV-geprüfte Kundenzufriedenheit für unsere curabox® und den pflege.de-Anbietervergleich.
    </p>
  </div>
</template>

<script setup>
const props = defineProps({
  copy: {
    type: Boolean,
    default: false
  }
})
</script>
<style scoped>
.tuev {
  display: flex;
  width: 300px;
  min-width: 300px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.copy {
  text-align: center;
  font-size: 0.9rem;
  margin-block: 0.5rem;
}

a,
img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 194px;
  height: 102px;
}
</style>
